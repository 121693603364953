export const VENTION_US_PHONE_NUMBER = '+1-800-940-3617'
export const VENTION_EU_PHONE_NUMBER = '+49-30-56795545'
export const VENTION_UK_PHONE_NUMBER = '+44-20-3695-2522'
export const VENTION_FR_PHONE_NUMBER = '+32-2-330-41-03'

export const VENTION_SALES_EMAIL_ADDRESS = 'sales@vention.io'
export const VENTION_SUPPORT_EMAIL_ADDRESS = 'support@vention.io'

export const VENTION_GENERIC_MACHINE_IMAGE =
  'https://assets.vention.io/png/generic_machine.png'


export const GRAPHQL_PUBLIC_API = `${window.location.protocol}/${window.location.host}/graphql_public`
export const GRAPHQL_PRIVATE_API = `${window.location.protocol}/${window.location.host}/graphql`