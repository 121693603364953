import { useQuery, UseQueryResult } from "@tanstack/react-query";

export const useQuerySimpleGQL = <TResult, TParams extends any[]>(
    keyName: string,
    params: TParams,
    request: ( ...args: TParams) => Promise<TResult>,
    enabled?: boolean | (() => boolean)
) : { result: UseQueryResult<TResult> }=> {

    const result = useQuery<TResult>({
        queryKey: [keyName, ...params],
        queryFn: async (): Promise<TResult> => {
            return request(...params)
        },
        enabled: typeof enabled === 'boolean' ? enabled : enabled? enabled() : true
    });

    return {
        result
    };
}