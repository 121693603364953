import * as React from "react";

import { cn } from "@/lib/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  noMargin?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, noMargin, label, type, ...props }, ref) => {
    return (
      <>
        {label && (
          <label className="text-sm-medium text-text-secondary mb-sm">
            {label}
          </label>
        )}
        <input
          type={type}
          className={cn(
            "tw-input flex p-lg text-md-regular !outline-none rounded-xs appearance-none bg-white border border-solid border-border-primary focus:border-border-brand focus:shadow-custom-hover text-gray-900 w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white",
            !noMargin && "mb-xl",
            className,
          )}
          ref={ref}
          {...props}
        />
      </>
    );
  },
);
Input.displayName = "Input";

export { Input };
